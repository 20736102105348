/* eslint-disable no-param-reassign */
import axios, { AxiosInstance } from 'axios';
import urls from 'configs/urls';
import { getPartnerName } from 'partner_configuration';
import { endpoints } from 'services/api/endpoints';
import { LogoutUser } from 'store/store';
import {
  getLocalStorageItem,
  isTokenExpiringSoon,
  setLocalStorageItem
} from 'utils/commonFunctions';
import {
  clearSession,
  getSessionStorageItem,
  refreshSession,
  setSessionStorageItem
} from 'utils/sessionFunctions';
import { v4 as uuidv4 } from 'uuid';

/**
 * Create multiple axios instances with different base URLs for easier
 * communication with different services.
 * Base URLs are dependent on current environment.
 * */

const babyPortalApi: AxiosInstance = axios.create({
  baseURL: urls.baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const returnNewToken = async (): Promise<{
  refresh_token: string;
  access_token: string;
}> => {
  try {
    console.log('Updating access token...');
    const res = await babyPortalApi.post(
      endpoints.updateAccessToken,
      {},
      {
        headers: {
          'Refresh-Token': getLocalStorageItem('refresh-token'),
          'skip-auth': true
        }
      }
    );
    console.log('Updated access token response', res);
    const { access_token, refresh_token } = res.data.data.token;
    return {
      access_token,
      refresh_token
    };
  } catch (error) {
    console.error('Error updating access token:', error);
    throw error;
  }
};

let tokenRefreshPromise: Promise<string | null> | null = null;

babyPortalApi.interceptors.request.use(async (request) => {
  console.log('Request interceptor', request);
  if (request && request.headers && !request.headers['skip-auth']) {
    let token = getLocalStorageItem('auth-token');
    if (token && isTokenExpiringSoon(token)) {
      console.log('Token is expiring soon, refreshing token...');
      if (!tokenRefreshPromise) {
        // Start token refresh
        tokenRefreshPromise = returnNewToken()
          .then(({ access_token, refresh_token }) => {
            if (access_token && refresh_token) {
              setLocalStorageItem('auth-token', access_token);
              setLocalStorageItem('refresh-token', refresh_token);
              return access_token;
            }
            return null;
          })
          .catch((error) => {
            console.error('Error refreshing token:', error);
            throw error;
          })
          .finally(() => {
            // Clear the refresh promise once completed
            tokenRefreshPromise = null;
          });
      }

      // Wait for the token refresh to complete
      token = await tokenRefreshPromise;
    }

    if (token) {
      request.headers['Auth-Key'] = token;
    }
  }

  const url = window.location.origin;
  const partnerName = getPartnerName(url);
  request.baseURL = urls.baseUrl;
  request.headers['Partner-ID'] = partnerName;
  request.headers['Client-ID'] = 'baby-user-portal';

  // Handle session ID
  let sessionID = getSessionStorageItem('session-id');
  if (!sessionID) {
    sessionID = refreshSession();
    setSessionStorageItem('session-id', sessionID);
  }
  request.headers['X-Session-Id'] = sessionID;
  request.headers['X-Request-Id'] = uuidv4();

  if (request.headers['skip-auth']) {
    delete request.headers['skip-auth']; // Remove skip-auth from header because it's not allowed in the backend
  }

  return request;
});

babyPortalApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    // const originalRequest = error.config;

    // Check if error.response exists before accessing its properties
    if (error.response) {
      // if (error.response.status === 401 && !originalRequest._retry) {
      //   originalRequest._retry = true;
      //   // Add refresh token logic here
      //   // Example: await refreshAccessToken();
      //   // for now we logging out user when recieve 401 or 403 status code
      //   // clearSession();
      //   // LogoutUser();
      // }

      // if (error.response.status === 403) {
      //   console.error('403 Forbidden error:', error.response.data);
      //   // Handle forbidden access case here
      //   // clearSession();
      //   // LogoutUser();
      // }
      if (error.response.status === 401 || error.response.status === 403) {
        console.log('errInterceptor perform logout');
        LogoutUser();
        clearSession();
      }
    } else {
      console.error('Error: ', error.message);
    }

    return Promise.reject(error); // Reject with error to maintain promise chain
  }
);

export { babyPortalApi };
