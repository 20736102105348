import * as Sentry from '@sentry/react';
import envConfig from 'configs/envConfig';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

const turnONSentryEvents =
  envConfig('env') === 'prod' ||
  envConfig('env') === 'staging' ||
  envConfig('env') === 'luna-stg' ||
  envConfig('env') === 'luna';

if (turnONSentryEvents) {
  Sentry.init({
    dsn: 'https://436c5f40160774f1341327cf5f14c9bb@o4507616560414720.ingest.us.sentry.io/4507616981680128',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      /* Integrations enabled by default */
      // breadcrumbsIntegration
      // browserApiErrorsIntegration
      // browserSessionIntegration
      // dedupeIntegration
      // functionToStringIntegration
      // globalHandlersIntegration
      // httpContextIntegration
      // inboundFiltersIntegration
      // linkedErrorsIntegration

      Sentry.browserProfilingIntegration,
      Sentry.browserTracingIntegration(),

      Sentry.replayIntegration({
        networkDetailAllowUrls: [window.location.origin],
        networkRequestHeaders: ['X-Request-Id', 'X-Session-Id', 'Auth-Key'],
        networkResponseHeaders: ['X-Custom-Header']
      }),
      // Sentry.captureConsoleIntegration(),
      Sentry.extraErrorDataIntegration({ depth: 6 }),
      Sentry.httpClientIntegration({ failedRequestStatusCodes: [[400, 599]] })
    ],
    release: envConfig('releaseVersion'),
    environment: envConfig('env'),
    enabled: turnONSentryEvents,
    normalizeDepth: 6,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // This option is required for capturing headers and cookies.
    sendDefaultPii: true,
    beforeSend(event, hint) {
      console.log(event, hint);
      return event;
    }
  });
}
