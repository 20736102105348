import { ConfigContext } from 'context';
import { useContext } from 'react';
import styled from 'styled-components';
import { Flex } from 'styles';

const CardHeading = styled.div`
  color: #000;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;

const HeadingIcon = styled.div`
  width: 16px;
  height: 32px;
  border-radius: 4px;
  background: #d7eea1;
  margin-right: 16px;
`;

const Content = styled.div`
  color: #484c4f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
`;

const Info = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;
  text-decoration-line: underline;
`;

export function CustomerServiceContent() {
  const config = useContext(ConfigContext);
  return (
    <>
      {/* <Flex justify="space-between"> */}
      <Flex>
        <HeadingIcon />
        <CardHeading>Cancel Subscription</CardHeading>
      </Flex>
      {/* </Flex> */}
      <Content>
        Please contact customer service to cancel your subscription via:
      </Content>
      <Flex align="center">
        <Content>Email: </Content>
        <Info>{config.email}</Info>
      </Flex>

      <Flex align="center">
        <Content>Phone number: </Content>
        <Info>{config.phone}</Info>
      </Flex>
    </>
  );
}
