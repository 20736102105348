/* eslint-disable no-param-reassign */
import Clarity from '@microsoft/clarity';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { enableClarity } from 'infrastructure/serviceConfigs/clarityConfig';
import { GetUserDetails } from 'services/api/UserApi';
import { UserResponseType, UserState } from 'typings/userTypes';
import { removeRequestHeaders } from 'utils/commonFunctions';

export const fetchUser = createAsyncThunk(
  'users/fetchUser',
  async (payload, thunkAPI) => {
    try {
      const response = await GetUserDetails();
      const responseData = response.data;
      if (
        enableClarity &&
        responseData &&
        responseData.user_account &&
        responseData.user_account.user_id
      )
        Clarity.identify(
          responseData?.user_account?.user_id?.toString(),
          '',
          '',
          // `${responseData.user_account?.first_name} ${responseData.user_account.last_name}`,
          responseData?.user_account?.user_id?.toString()
        );
      console.log('responseData', responseData);
      Sentry.setUser({
        email: responseData?.user_account?.email,
        id: responseData?.user_account?.user_id,
        username: `${responseData?.user_account?.first_name} ${responseData?.user_account?.last_name}`
      });
      return responseData;
    } catch (error) {
      console.log('Error', error);
      return thunkAPI.rejectWithValue('failed');
    }
  }
);

const initialUserState: UserState = {
  userDetails: null,
  isLoading: false,
  isAuthenticated: false,
  isEligibleForTrial: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    clearUser: (state) => {
      state.userDetails = null;
      state.isAuthenticated = false;
      state.isEligibleForTrial = false;
      removeRequestHeaders();
    },
    updateFreeTrialEligibility: (state, action) => {
      state.isEligibleForTrial = action.payload || false;
    },
    updateUserDetails: (state, action) => {
      state.userDetails = action.payload as UserResponseType;
      state.isAuthenticated = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetails = action.payload.user_account as UserResponseType;
        state.isAuthenticated = true;
      })
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.isLoading = false;
        state.isAuthenticated = false;
      });
  }
});

// Action creators are generated for each case reducer function
export const { clearUser, updateFreeTrialEligibility, updateUserDetails } =
  userSlice.actions;

export default userSlice.reducer;
