/* eslint-disable import/no-extraneous-dependencies */
import { Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import envConfig from './envConfig';

loadStripe.setLoadParameters({ advancedFraudSignals: false });

const stripeKey = envConfig('stripeKey') || '';
if (!stripeKey) {
  console.log('Stripe is not configured');
}

// eslint-disable-next-line import/no-mutable-exports
let stripePromise: Promise<Stripe | null> | null = null;

// Function to initialize Stripe to avoid top-level await
async function initializeStripe(): Promise<Stripe | null> {
  if (!stripeKey) {
    console.warn('No Stripe Key available');
    return null;
  }
  if (!stripePromise) {
    stripePromise = loadStripe(stripeKey);
  }
  return stripePromise;
}

const stripeOptions: StripeElementsOptions = {
  clientSecret: '',
  appearance: {
    theme: 'stripe',
    labels: 'above',
    variables: {
      fontFamily: 'Sohne, system-ui, sans-serif',
      fontSizeBase: '18px',
      fontWeightNormal: '500',
      borderRadius: '8px'
      // colorBackground: 'black',
      // colorPrimary: '#EFC078',
      // colorPrimaryText: '#1A1B25',
      // colorText: 'black',
      // colorTextSecondary: 'black',
      // colorTextPlaceholder: '#727F96',
      // colorIconTab: 'black',
      // colorLogo: 'dark'
    },
    rules: {
      '.Input, .Block': {
        backgroundColor: 'transparent',
        border: '1.5px solid #F0F4F4',
        borderRadius: '25px'
      },
      '.DropdownItem': {
        backgroundColor: '#F1F1F1'
      },
      '.DropdownItem--highlight': {
        backgroundColor: '#EDEDED'
      }
    }
  }
};

const errorCodes = {
  GENERIC_DECLINE: 'generic_decline'
};

const stripeErrorMessages = {
  ADDRESS_MISMATCH:
    "The billing address you entered doesn't match the details associated with your card. Please check and update the address or use a different payment method.",
  NO_PREPAID_CARDS: 'Prepaid cards and Gift cards are not accepted.'
};

export {
  errorCodes,
  initializeStripe,
  stripeErrorMessages,
  stripeOptions,
  stripePromise
};
