import { combineReducers, configureStore } from '@reduxjs/toolkit';
// import logger from 'redux-logger';
import * as Sentry from '@sentry/react';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import subscriptionReducer, {
  clearSubscriptionData
} from './reducers/subscriptionReducer';
import userReducers, { clearUser } from './reducers/userReducers';

const persistConfig = {
  key: 'root',
  storage
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const rootReducer = combineReducers({
  user: userReducers,
  subscription: subscriptionReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  enhancers: (existingEnhancers) => [...existingEnhancers, sentryReduxEnhancer]
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const clearReduxStates = () => {
  return store.dispatch(clearSubscriptionData());
};

export const LogoutUser = () => {
  store.dispatch(clearUser());
  clearReduxStates();
};
