/* eslint-disable react/function-component-definition */
/* eslint-disable no-unneeded-ternary */

import { Button, Dialog, IconButton, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';

import { babyPortalApi } from 'data/axios';
import { useLocation } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddOrRemoveCamera from 'components/AddOrRemoveCamera';
import Spinner from 'components/Spinner';
import { endpoints } from 'services/api/endpoints';
import { GetPlanDetails, Plans } from 'services/api/plansApi';
import {
  CheckFreeTrialSubscription,
  SubscriptionType
} from 'services/api/subscriptionApi';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchAllSubscription } from 'store/reducers/subscriptionReducer';
import styled from 'styled-components';
import { DeviceResponseType } from 'typings/deviceTypes';
import BuySubscription from 'views/BuySubscription/BuySubscription';
import { CustomerServiceContent } from './componenets/CustomerServiceContent';
import NoSubscription from './componenets/NoSubscription';
import { SubscriptionInfoCard } from './componenets/SubscriptionInfoCard';

const Container = styled.div`
  & > div {
    margin-bottom: 20px;
  }
`;

export const SubscriptionDetails = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPolling, setIsPolling] = useState(false);
  const [showSnackBar, toggleSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [selectedSubscription, setSelectedSubscription] =
    useState<SubscriptionType | null>(null);
  const [plans, setPlans] = useState<Plans[]>([]);
  const [selectedSpaceDeviceData, setSelectedSpaceDeviceData] = useState<
    DeviceResponseType[]
  >([]);

  const [isEligibleForTrial, setIsEligibleForTrial] = useState(false);
  const subscriptionData = useAppSelector<SubscriptionType[]>(
    (state) => state.subscription.subscriptionData
  );

  const location = useLocation();
  const dispatch = useAppDispatch();

  const query = new URLSearchParams(location.search);
  // const deviceIdFromQuery = query.get('deviceId') || '';
  const subscriptionIdFromQuery = query.get('subscriptionId') || '';
  const pairingFlowFromQuery =
    query.get('pairingFlow') === 'true' ? true : false;

  // const userHasActiveSubscription =
  //   subscriptionData?.length &&
  //   subscriptionData[0]?.subscribe_status === 'Active';

  console.log('subscriptionData', subscriptionData);
  // console.log('userHasActiveSubscription', userHasActiveSubscription);

  const openModal = (value: string) => {
    setOpen(true);
    setSelectedValue(value);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedValue('');
  };

  const toggleModal = () => {
    setOpen((state) => !state);
  };

  const hideSnackBar = () => {
    toggleSnackBar(false);
  };

  const fetchSubscriptionData = () => {
    setIsLoading(true);
    setIsPolling(true);
  };

  useEffect(() => {
    if (
      location?.state?.isSubscriptionUpgraded &&
      location?.state?.oldSubscriptionId
    ) {
      setIsLoading(true);

      const found = subscriptionData.find(
        (item) => item.purchase_id === location?.state?.oldSubscriptionId
      );
      if (found) {
        dispatch(fetchAllSubscription());
      } else {
        setIsLoading(false);
      }
    }
  }, [
    location?.state?.isSubscriptionUpgraded,
    location?.state?.oldSubscriptionId,
    subscriptionData
  ]);

  useEffect(() => {
    if (isPolling) {
      const found = subscriptionData.find(
        (item) => item.purchase_id === selectedSubscription?.purchase_id
      );
      if (found) {
        dispatch(fetchAllSubscription());
      } else {
        setIsPolling(false);
        setIsLoading(false);
        setSnackBarMsg(
          'Your Subscription is successfully cancelled during free trial period. Your card will not be charged.'
        );
        toggleSnackBar(true);
      }
    }
  }, [isPolling, subscriptionData]);

  useEffect(() => {
    setIsLoading(true);

    Promise.all([
      babyPortalApi.get(endpoints.listDevicesUrl),
      GetPlanDetails(),
      CheckFreeTrialSubscription()
    ])
      .then((res) => {
        console.log('In details I am here 80 - ', res);
        dispatch(fetchAllSubscription());

        const [spaceDevices, allPlans, freeTrialEligibility] = res;
        setSelectedSpaceDeviceData(spaceDevices.data.data.devices);
        setIsEligibleForTrial(freeTrialEligibility.data.trial);
        setPlans(allPlans.data);
      })
      .catch((err) => {
        console.log('In details I am here 88 - ', err);
        console.log('err', err);
      })
      .finally(() => {
        console.log('In details I am here 92 - ');
        // debugger;
        if (subscriptionIdFromQuery) {
          const found = subscriptionData.find(
            (item) => item.purchase_id === subscriptionIdFromQuery
          );
          // debugger;
          if (found) {
            setSelectedSubscription(found);
            openModal('MODIFY');
          }
        }
        setIsLoading(false);
      });
  }, []);

  const snackBarAction = (
    <IconButton size="small" color="inherit" onClick={hideSnackBar}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  if (isLoading) {
    return <Spinner />;
  }

  console.log('In details plans - ', plans);
  console.log('In details isLoading - ', isLoading);
  console.log(
    'In details page selectedSpaceDeviceData - ',
    selectedSpaceDeviceData
  );

  // debugger;
  return subscriptionData?.length === 0 ? (
    <>
      <NoSubscription
        isEligibleForTrial={isEligibleForTrial}
        deviceId={selectedSpaceDeviceData[0]?.device_id}
      />
      {showSnackBar && snackBarMsg && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={showSnackBar}
          autoHideDuration={5000}
          onClose={hideSnackBar}
          message={snackBarMsg}
          action={snackBarAction}
        />
      )}
    </>
  ) : (
    <Container>
      <Dialog
        PaperProps={{
          style: {
            maxWidth: '100%',
            minWidth: '50%',
            padding: '20px'
          }
        }}
        onClose={onClose}
        open={open}
      >
        <Button
          sx={{
            position: 'absolute',
            right: '20px',
            top: '20px',
            padding: '0px',
            width: '20px',
            minWidth: '20px',
            height: '20px',
            border: 'none',
            outline: 'none'
          }}
          onClick={onClose}
        >
          <CloseOutlinedIcon />
        </Button>
        {selectedValue === 'ADD_OR_REMOVE_CAMERA' && (
          <AddOrRemoveCamera
            selectedSubscription={selectedSubscription}
            openModal={openModal}
          />
        )}
        {selectedValue === 'CANCEL' && <CustomerServiceContent />}
        {selectedValue === 'MODIFY' && (
          <BuySubscription
            isUpgrade
            isOnHigherPlan={Boolean(
              selectedSubscription &&
                selectedSubscription.subscribe_status === 'Active' &&
                selectedSubscription.subscription_cycle === 12
            )}
            isFreeTrial={isEligibleForTrial}
            togglePrimaryModal={toggleModal}
            selectedSubscription={selectedSubscription}
            pairingFlow={pairingFlowFromQuery}
          />
        )}
      </Dialog>
      {!isLoading &&
        subscriptionData.length > 0 &&
        plans.length > 0 &&
        subscriptionData?.map((item) => (
          <SubscriptionInfoCard
            plans={plans}
            key={item.id}
            openModal={openModal}
            subscriptionInfo={item}
            setSelectedSubscription={setSelectedSubscription}
            onCancelFreeTrial={fetchSubscriptionData}
          />
        ))}
      {showSnackBar && snackBarMsg && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={showSnackBar}
          autoHideDuration={5000}
          onClose={hideSnackBar}
          message={snackBarMsg}
          action={snackBarAction}
        />
      )}
    </Container>
  );
};
