/* eslint-disable @typescript-eslint/no-explicit-any */
import CircularProgress from '@mui/material/CircularProgress';
import { babyPortalApi } from 'data/axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchUser } from 'store/reducers/userReducers';
import { clearReduxStates } from 'store/store';
import styled from 'styled-components';
import { Flex } from 'styles';
import { setLocalStorageItem, setRequestHeaders } from 'utils/commonFunctions';

const ContentContainer = styled.div`
  font-size: 30px;
  margin: 20px;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  gap: 10px;
`;

type StatusMessages = {
  [key: string]: string;
};
const statusMessage: StatusMessages = {
  authorizing: 'Authorizing Request...',
  token_expired: 'Link is expired or invalid. Please generate link again.'
};

function Bridge() {
  const [status, setStatus] = useState<string>('authorizing');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const [customTokenVerified, setCustomTokenVerified] = useState(false);
  const query = new URLSearchParams(location.search);
  const token = query.get('token') || '';

  type Module = {
    [key: string]: any;
    url: string;
  };

  const modulesMapping = {
    dashboard: {
      url: '/ActiveDevices'
    },
    planlist: {
      url: '/planlist',
      params: 'deviceId'
    },
    subscription: {
      url: '/MySubscription',
      params: 'subscriptionId'
    },
    authorizeDevice: {
      url: '/authorizeFromApp',
      authorizationParams: {
        deviceId: 'device_id'
      }
    }
  };

  const getRedirectionURL = () => {
    const redirectModuleName = query.get('redirect') || '';
    // debugger;
    let url = '/';
    if (redirectModuleName) {
      const module: Module =
        modulesMapping[redirectModuleName as keyof typeof modulesMapping];
      //   debugger;
      url = module.url;
      if (module?.params) {
        url += `?${module?.params}=${query.get(module?.params)}`;
      }
      if (module?.authorizationParams) {
        url += `?device_id=${query.get(module?.authorizationParams.deviceId)}`;
      }
      url += '&pairingFlow=false';
    }
    return url;
  };

  const verifyToken = (one_time_token: string) => {
    babyPortalApi
      .post(
        `/oauth2/one-time-token/login?token=${one_time_token}`,
        {},
        {
          headers: {
            'skip-auth': true
          }
        }
      )
      .then((res) => {
        const { access_token, refresh_token } = res.data.data.token;
        setRequestHeaders(access_token);
        setLocalStorageItem('refresh-token', refresh_token);
        dispatch(fetchUser());
        setCustomTokenVerified(true);
      })
      .catch((err) => {
        setStatus('token_expired');
        console.log('fetchAccessToken', err);
      });
  };
  useEffect(() => {
    if (token === '') {
      setStatus('token_expired');
      return;
    }
    clearReduxStates();
    verifyToken(token);
  }, [token]);

  useEffect(() => {
    if (isAuthenticated && customTokenVerified) {
      const redirectionURL = getRedirectionURL();
      //   debugger;
      navigate(redirectionURL);
    }
  }, [isAuthenticated, customTokenVerified]);
  return (
    <Flex justify="center" align="center" height="100vh">
      <ContentContainer>
        {status === 'authorizing' && <CircularProgress size={30} />}
        {statusMessage[status]}
      </ContentContainer>
    </Flex>
  );
}
export default Bridge;
