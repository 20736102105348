/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button, styled as styledMUI } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { babyPortalApi } from 'data/axios';
import { endpoints } from 'services/api/endpoints';

import Spinner from 'components/Spinner';
import { Flex } from 'styles';

import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as VideoCam } from 'assets/video-cam.svg';

const Container = styledMUI('div')(({ theme }) => ({
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '320px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '6px'
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '440px'
  }
}));

const Title = styled.div`
  color: #000;
  margin-top: 14px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const Heading = styled.div`
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.01em;
  margin: 32px 0 12px;
`;

const Info = styled.div`
  color: #484c4f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const CameraBox = styledMUI('div')<{
  isCovered?: boolean;
  isSelected?: boolean;
}>(({ theme, isCovered, isSelected }) => ({
  display: 'flex',
  width: '100%',
  padding: '16px 20px',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: isSelected ? theme.palette.primary.main : '#f0f4f4',
  borderRadius: '16px',
  cursor: isCovered ? 'not-allowed' : 'pointer'
}));

const CameraName = styledMUI('div')<{
  isCovered?: boolean;
  isSelected?: boolean;
}>(({ theme, isSelected }) => ({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0px',
  textAlign: 'left',
  color: isSelected ? '#fff' : theme.palette.primary.main
}));

const PlanName = styled.div<{
  isCovered?: boolean;
  isSelected?: boolean;
}>`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props) => (props.isSelected ? '#fff' : '#000')};
`;

const VideoCamIcon = styled(VideoCam)<{
  isSelected?: boolean;
}>`
  & path {
    stroke: ${(props) => (props.isSelected ? '#fff' : '#000')};
  }
`;

export default function SelectDevice() {
  const location = useLocation();

  const {
    isUpgrade,
    isFreeTrial,
    planSelected,
    purchaseId,
    deviceId,
    dontAddDevice,
    oldSubscription
  } = location.state;

  const [isLoading, setIsLoading] = useState(false);
  const [allDevices, setAllDevices] = useState([]);
  const [sortedAllDevices, setSortedAllDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const navigate = useNavigate();

  const fetchAllDevices = () => {
    setIsLoading(true);

    babyPortalApi
      .get(endpoints.listDevicesUrl)
      .then((res) => {
        const allDevicesFromResponse = res.data.data.devices.map(
          (item: any) => {
            const device = item;
            device.isCovered = Boolean(device.sku_id);
            return device;
          }
        );

        const sortedByIsCovered = allDevicesFromResponse.sort(
          (a: any, b: any) =>
            // eslint-disable-next-line no-nested-ternary
            a.isCovered === b.isCovered ? 0 : a.isCovered ? -1 : 1
        );

        console.log('sortedAllDevices ', sortedByIsCovered);
        setSortedAllDevices(sortedByIsCovered);

        console.log('allDevicesFromResponse ', allDevicesFromResponse);
        setAllDevices(allDevicesFromResponse);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAllDevices();
  }, []);

  const handleSelectDevice = (device: any) => {
    if (device.isCovered) {
      return;
    }
    if (!device.isCovered) {
      setSelectedDevice(device.device_id);
    }
  };

  const { name } = planSelected;

  return (
    <Container>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Flex direction="column" align="start" gap="24px">
          <Flex direction="column" align="start">
            <Title>
              <IconButton
                onClick={() => {
                  navigate('/planlist');
                }}
              >
                <KeyboardArrowLeftRoundedIcon />
              </IconButton>{' '}
              Subscribe to InstaView Baby
            </Title>
            <Heading>
              {`Which devices should be covered by ${name} Plan?`}
            </Heading>
          </Flex>

          {allDevices.length === 0 && (
            <Info>
              It looks like you do not have any paired camera. Please go to
              InstaView Baby app and add camera to continue the setup.
            </Info>
          )}
          {sortedAllDevices.map((item) => {
            const { infant_name, device_id, isCovered } = item;
            return (
              <CameraBox
                onClick={() => handleSelectDevice(item)}
                isCovered={isCovered}
                isSelected={device_id === selectedDevice}
                key={device_id}
              >
                <Flex direction="column" align="start" gap="8px">
                  <CameraName
                    isSelected={device_id === selectedDevice}
                  >{`${infant_name}'s Cam`}</CameraName>
                  <PlanName isSelected={device_id === selectedDevice}>{`${
                    isCovered ? 'Covered' : 'Not covered'
                  }`}</PlanName>
                </Flex>
                <VideoCamIcon isSelected={device_id === selectedDevice} />
              </CameraBox>
            );
          })}

          <Flex justify="flex-end" style={{ width: '100%', marginTop: '16px' }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                outline: 'none',
                '&:focus': {
                  outline: 'none'
                }
              }}
              style={{
                width: '50%',
                maxWidth: '300px',
                borderRadius: '44px'
              }}
              onClick={(e) => {
                e.preventDefault();
                navigate('/payment-selections', {
                  state: {
                    isUpgrade,
                    isFreeTrial,
                    planSelected,
                    purchaseId,
                    deviceId,
                    dontAddDevice,
                    oldSubscription,
                    selectedDevice
                  }
                });
              }}
              disabled={selectedDevice === null}
            >
              Continue
            </Button>
          </Flex>
        </Flex>
      )}
    </Container>
  );
}
